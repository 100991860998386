<template>
  <div>
    <v-card>
      <v-card-title class="py-0">
        <v-row
          no-gutters
          class="ma-0 pa-0"
          justify="space-between"
        >
          <v-col
            class="ma-0 pa-0"
            cols="6"
            md="3"
            align="left"
          ><span class="text-caption font-weight-light"> #{{
            comment.id }}</span>
            <span><fake-button
                icon
                large
                @click="newCommentDialog(newComment, comment.id)"
              ><v-icon>mdi-reply</v-icon></fake-button></span>
          </v-col>
          <v-col
            class="ma-0 pa-0"
            cols="6"
            md="3"
            align="right"
            align-self="end"
          ><span class="text-body-2">{{
            comment.created_at | moment("DD.MM.YYYY [o] HH:mm") }}</span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="text-body">
        <v-row
          no-gutters
          justify="center"
          align="end"
        >
          <v-col cols="11">
            <read-more-text
              :length="250"
              :text="comment.text"
              :commentId="comment.id"
              :clickCallBack="commentRead"
            ></read-more-text>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            v-show="comment.attachments.length > 0"
            cols="10"
            align="center"
          >
            <sources-component :attachments="comment.attachments"></sources-component>
          </v-col>
        </v-row>
        <v-row
          v-if="comment.can_edit"
          dense
          justify="space-between"
        >
          <v-col cols="4">
            <v-btn
              color="error"
              small
              @click="removeComment(comment, comment.reaction_to)"
            >
              Zmazať
            </v-btn>
          </v-col>
          <v-col
            cols="4"
            class="text-right"
          >
            <v-btn
              color="primary"
              small
              @click="updateCommentDialog(comment, comment.reaction_to)"
            >
              Upraviť
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-row
          align="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  v-if="comment.user.photo"
                  class="elevation-6"
                  alt=""
                  :src="comment.user.photo"
                  style="cursor: pointer"
                  @click="selectedItem = comment.user.photo"
                ></v-img>
                <v-icon
                  v-else
                  x-large
                >mdi-ab-testing</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ comment.user.first_name }} {{ comment.user.last_name }} <br />{{ comment.user.address_town }}
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="pa-0 ma-0"
                        color="error"
                        size="18"
                        style="margin-bottom: 1px;"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      ><v-icon>mdi-alert-outline</v-icon></v-btn>
                    </template>
                    <v-card><v-card-text>
                        <p>Za obsah tohto príspevku do diskusie nenesie zodpovednosť prevádzkovateľ tejto stránky. Za obsah
                          príspevku preberá plnú zodpovednosť jeho autor - {{ comment.user.first_name }}
                          {{ comment.user.last_name }}.</p>
                      </v-card-text></v-card>
                  </v-menu></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            cols="12"
            sm="auto"
          >
            <fake-button
              v-if="comment.user_voted == true"
              icon
              small
              color="amber amber-darken-4"
              class="mx-1"
              @click="voteUp(comment.id)"
            ><v-icon>mdi-thumb-up
              </v-icon></fake-button>
            <fake-button
              v-else
              icon
              small
              color="amber amber-darken-4"
              class="mx-1"
              @click="voteUp(comment.id)"
            ><v-icon>mdi-thumb-up-outline
              </v-icon></fake-button>
            <span class="mr-2">{{ comment.votes_yes_count }}</span>
            <span class="mr-1">·</span>

            <fake-button
              v-if="comment.user_voted == false"
              icon
              small
              color="black"
              class="mx-1"
              @click="voteDown(comment.id)"
            ><v-icon>mdi-thumb-down
              </v-icon></fake-button>
            <fake-button
              v-else
              icon
              small
              color="black"
              class="mx-1"
              @click="voteDown(comment.id)"
            ><v-icon>mdi-thumb-down-outline
              </v-icon></fake-button>
            <span class="mr-2">{{ comment.votes_no_count }}</span>

            <span class="mr-1">·</span>
            <v-icon class="mx-1">
              mdi-scoreboard-outline
            </v-icon>
            <span class="mr-2 text-h6 font-weight-regular">{{ comment.hodnotenie }}</span>

            <span class="mr-1">·</span>
            <v-icon class="mx-1">
              mdi-eye-outline
            </v-icon>
            <span>{{ comment.read_count }}</span>
          </v-col>
        </v-row>
      </v-card-actions>

      <comment
        v-for="subComment in comment.replies"
        :depth="depth + 1"
        v-bind:key="subComment.id"
        :class="'pl-' + depth + ' my-1'"
        :comment="subComment"
        :title="title"
        :type="type"
        :questionID="questionID"
        :surveyID="surveyID"
        :partyID="partyID"
        :sync="sync"
        :question="question"
        :createCommentFunc="createCommentFunc"
        :getCommentsFunc="getCommentsFunc"
        :updateCommentFunc="updateCommentFunc"
        :deleteCommentFunc="deleteCommentFunc"
        :voteUpCommentFunc="voteUpCommentFunc"
        :voteDownCommentFunc="voteDownCommentFunc"
        :readCommentFunc="readCommentFunc"
        :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
        :createCommentAttachmentFunc="createCommentAttachmentFunc"
        :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
        :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
        :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
        @sync-child="syncComments"
      >
      </comment>

    </v-card>

    <v-overlay
      v-if="selectedItem"
      @click="selectedItem = null"
    >
      <v-container>
        <v-row justify="center">
          <v-col align="center">
            <v-img
              max-width="300px"
              max-height="300px"
              :src="selectedItem"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="newComment.reaction_to">Moja odpoveď na dôvod</v-toolbar-title>
          <v-toolbar-title v-else>Upraviť Môj dôvod</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              light
              color="success"
              rounded
              class="pa-5"
              @click="updateComment(newComment)"
            ><span v-if="newComment.reaction_to">Odoslať Moju odpoveď</span><span v-else>Upraviť Môj dôvod</span></v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>

        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-select
                  label="Sem pridaj typ"
                  v-model="newComment.type"
                  :items="[{ text: 'ÁNO', value: 'YES' }, { text: 'NIE', value: 'NO' }, { text: 'ZDRŽANIE SA', value: 'ABSTAIN' }]"
                ></v-select>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Znenie komentáru</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea
                  label="Sem pridaj znenie tvojho komentáru"
                  rows="1"
                  auto-grow
                  v-model="newComment.text"
                ></v-textarea>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Odkazy</v-list-item-title>
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col><v-btn
                      color="primary"
                      small
                      @click="addAttachment(newComment.id, 'TEXT')"
                    >
                      Pridať
                    </v-btn></v-col>
                </v-row>

                <v-card
                  class="ma-1"
                  v-for="item in getAttachmentsType(newComment.attachments, 'TEXT')"
                  v-bind:key="item.id"
                  outlined
                >
                  <v-card-text>

                    <poll-discussion-source-text-edit
                      :item="item"
                      :create="newComment.create"
                      :createCommentAttachmentFunc="createCommentAttachmentFunc"
                      :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
                      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
                      @sync-child="syncComments"
                    ></poll-discussion-source-text-edit>
                  </v-card-text>

                </v-card>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Obrázky</v-list-item-title>
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col><v-btn
                      color="primary"
                      small
                      @click="addAttachment(newComment.id, 'IMAGE')"
                    >
                      Pridať
                    </v-btn></v-col>
                </v-row>
                <v-card
                  class="ma-1"
                  v-for="item in getAttachmentsType(newComment.attachments, 'IMAGE')"
                  v-bind:key="item.id"
                  outlined
                >
                  <v-card-text>
                    <poll-discussion-source-image-edit
                      :item="item"
                      :create="newComment.create"
                      :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
                      :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
                      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
                      @sync-child="syncComments"
                    ></poll-discussion-source-image-edit>
                  </v-card-text>
                </v-card>
              </v-list-item-subtitle>
            </v-list-item-content></v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Videá</v-list-item-title>
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col><v-btn
                      color="primary"
                      small
                      @click="addAttachment(newComment.id, 'VIDEO')"
                    >
                      Pridať
                    </v-btn></v-col>
                </v-row>

                <v-card
                  class="ma-1"
                  v-for="item in getAttachmentsType(newComment.attachments, 'VIDEO')"
                  v-bind:key="item.id"
                  outlined
                >
                  <v-card-text>
                    <poll-discussion-source-video-edit
                      :item="item"
                      :create="newComment.create"
                      :createCommentAttachmentFunc="createCommentAttachmentFunc"
                      :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
                      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
                      @sync-child="syncComments"
                    ></poll-discussion-source-video-edit>
                  </v-card-text>
                </v-card>
              </v-list-item-subtitle></v-list-item-content></v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import SourcesComponent from './PollDiscussionSources.vue';
import PollDiscussionSourceTextEdit from './PollDiscussionSourceTextEdit.vue';
import PollDiscussionSourceImageEdit from './PollDiscussionSourceImageEdit.vue';
import PollDiscussionSourceVideoEdit from './PollDiscussionSourceVideoEdit.vue';
import FakeButton from '../../FakeButton.vue';
import ReadMoreText from "@/components/ReadMoreText.vue";


export default {
  name: "comment",

  data() {
    return {
      dialog: false,

      newComment: {
        id: null,
        text: "",
        type: this.type,
        parent: "",
        parent_survey: "",
        parent_party: "",
        reaction_to: null,

        attachments: []
      },

      selectedItem: null,
    }
  },

  props: {
    depth: { type: Number, required: true },
    comment: { type: Object, required: true },
    title: { type: String, required: true },
    type: { type: String, required: true },
    questionID: { type: Number, required: false },
    surveyID: { type: Number, required: false },
    partyID: { type: Number, required: false },
    sync: { type: Boolean, required: false, default: false },
    question: { type: Object, required: false },
    createCommentFunc: { type: Function, requred: true },
    getCommentsFunc: { type: Function, required: true },
    updateCommentFunc: { type: Function, required: true },
    deleteCommentFunc: { type: Function, required: true },
    voteUpCommentFunc: { type: Function, required: true },
    voteDownCommentFunc: { type: Function, required: true },
    readCommentFunc: { type: Function, required: true },
    createCommentAttachmentFormDataFunc: { type: Function, required: true },
    createCommentAttachmentFunc: { type: Function, required: true },
    updateCommentAttachmentFormDataFunc: { type: Function, required: true },
    updateCommentAttachmentFunc: { type: Function, required: true },
    deleteCommentAttachmentFunc: { type: Function, required: true },
  },

  components: {
    ReadMoreText,
    SourcesComponent,
    PollDiscussionSourceTextEdit,
    PollDiscussionSourceImageEdit,
    PollDiscussionSourceVideoEdit,
    FakeButton
  },

  methods: {
    syncComments() {
      this.$emit('sync-child');
    },

    getAttachmentsType(attachments, type) {
      return attachments.filter(function (obj) {
        return obj.type == type && !obj.deleted;
      });
    },

    addAttachment(comment, type) {
      this.newComment.attachments.push({
        id: null,
        type: type,
        video: null,
        image: null,
        title: null,
        text: null,
        comment: comment,
        deleted: false
      })
    },

    commentRead(id) {
      this.readCommentFunc(id);
    },
    voteUp(id) {
      this.voteUpCommentFunc(id).then(() => {
        this.$emit("sync-child");
      })
    },

    voteDown(id) {
      this.voteDownCommentFunc(id).then(() => {
        this.$emit("sync-child");
      });
    },

    newCommentDialog(item, reaction_to) {
      this.newComment.create = true;
      this.newComment.reaction_to = reaction_to;

      this.newComment.parent = item.parent;
      this.newComment.parent_survey = item.parent_survey;
      this.newComment.parent_party = item.parent_party;

      if (reaction_to) {
        this.newComment.type = "";
        this.newComment.text = "";
        this.newComment.id = "";
        this.newComment.attachments = [];
      } else {
        this.newComment.type = item.type;
        this.newComment.text = item.text;
        this.newComment.id = item.id;
        this.newComment.attachments = item.attachments;
      }

      this.dialog = true;
    },

    updateCommentDialog(item, reaction_to) {
      this.newComment.create = false;
      this.newComment.reaction_to = reaction_to;

      this.newComment.parent = item.parent;
      this.newComment.parent_survey = item.parent_survey;
      this.newComment.parent_party = item.parent_party;

      this.newComment.type = item.type;
      this.newComment.text = item.text;
      this.newComment.id = item.id;
      this.newComment.attachments = item.attachments;

      this.dialog = true;
    },


    createComment(comment) {
      if (this.questionID)
        this.newComment.parent = this.questionID;
      else {
        this.newComment.parent_survey = this.surveyID;
        this.newComment.parent_party = this.partyID;
      }

      this.createCommentFunc(comment).then(response => {
        let comment_id = response.id;

        comment.attachments.forEach(async (item) => {
          if (item.deleted)
            return;

          let formData = new FormData();

          for (var key in item) {
            if (key == "image") continue;

            if (item[key] != null) formData.set(key, item[key]);
          }

          if (item.image_file instanceof File)
            formData.append("image", item.image_file);

          formData.set("comment", comment_id);

          await this.createCommentAttachmentFormDataFunc(formData);
        });

        this.$emit("sync-child");

        this.newComment.id = null;
        this.newComment.type = "";
        this.newComment.text = "";
        this.newComment.attachments = [];
        this.dialog = false;
      })
    },

    removeComment(comment) {
      confirm("Naozaj zmazať?") && this.deleteCommentFunc(comment.id).then(() => {
        this.$emit("sync-child");
      });
    },

    updateComment(comment) {
      if (comment.id) {
        this.updateCommentFunc(comment).then(() => {
          this.$emit("sync-child");
          this.dialog = false;
        })
      } else {
        this.createComment(comment);
      }
    },

  }

}
</script>

<style></style>